import { lazy, type ComponentProps, type ReactElement, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useMatch, useSearchParams } from "react-router-dom";

import type { DefaultAppFrameWithQuery } from "common/app_frame/default";
import { useIsAuthenticated, HardLoginRedirect } from "common/authentication";
import { MfaSetupBlocker } from "common/authentication/mfa_setup_blocker";
import APP from "constants/applications";
import App from "common/app";
import Env from "config/environment";
import { TIER_PATH } from "constants/tier";
import TargetAuthLinkHandler from "common/target_auth_link_handler";
import { composeViewerWrappers, createViewerManager } from "util/viewer_wrapper";
import { CustomerRedirectChecker } from "common/authorization";
import { PermissionRedirect } from "common/core/current_user_role";
import {
  BASE_PATH as JOURNAL_BASE_PATH,
  COPY_REQUEST_PATH,
  CERTIFY_PATH,
  DOWNLOAD_EXPORT_PATH,
} from "common/notary/journal/routing";
import Activation from "common/account/activation";
import OrganizationRoleContext from "common/organization/organization_role_context";
import InitialActiveOrganizationSetter from "common/organization/initial_active_organization_setter";
import { makeUserManagementRoutes } from "common/organization/member_management/routing";
import NotaryTechCheck from "common/tech_check/notary_tech_check";
import TierBlocker from "common/tier/blocker";
import TermsOfServiceBlocking, {
  TermsOfServiceBlockingAutoAcceptForNewUsers,
} from "common/modals/terms_of_service/blocking";
import { useSignerFeatureGatingSpecialization } from "signer_portal/feature_gating";
import ShortcutCheatsheet from "common/shortcut_cheatsheet";
import SmsMfaSetup from "common/notary/onboarding/mfa/sms";
import TotpMfaSetup from "common/notary/onboarding/mfa/totp";
import SupportChat from "common/support/chat";
import Logger from "common/logger";
import BundleAnalyticsContextRootContainer from "common/document_bundle/analytics_context";
import Settings from "common/settingsv2";
import PreMeetingRedirect from "signer_portal/pre_meeting/redirect";
import { PATH as PRE_MEETING_HACKS_PATH } from "signer_portal/hacks/pre_meeting/routing";
import LoadingIndicator from "common/core/loading_indicator";
import ProofFrame from "common/proof_frame";
import PortalGateway from "common/authentication/portal_gateway";
import { SIGN_PATH } from "util/routes";
import { COMMAND_CENTER_PATH } from "common/proof_frame/path";
import { ClearPendoTransforms } from "util/pendo";
import { useCommandCenterAccessControls } from "util/feature_detection";
import { RecipientColorProvider } from "common/pdf/recipient_colors/context";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";

import RedirectRoot from "./redirect_root";
import ViewerQuery, { type SignerRouterViewer_viewer as Viewer } from "./viewer_query.graphql";
import LimitedSessionWrapper from "./limited_session";

const UserCertificate = lazy(() => import("signer_portal/certificates"));
const SessionExpiredModal = lazy(() => import("common/authorization/session_expired_modal"));
const SigningGroupClaim = lazy(() => import("signer_portal/signing_group/claim"));
const EasyLink = lazy(() => import("common/account/easy_link"));
const HandleTransactionLink = lazy(() => import("signer_portal/handle_transaction_link"));
const SignerDashboard = lazy(() => import("signer_portal/dashboard"));
const GuestSignup = lazy(() => import("common/account/guest_signup"));
const ReferralHandler = lazy(() => import("signer_portal/referral_handler"));
const Login = lazy(() => import("common/account/login"));
const VerifyEmail = lazy(() => import("common/account/verify_email"));
const ResetPassword = lazy(() => import("common/account/login/reset_password"));
const Dropbox = lazy(() => import("common/integrations/dropbox"));
const TierUpgrade = lazy(() => import("common/tier/upgrade"));
const MeetingRedirect = lazy(() => import("signer_portal/meeting_redirect"));
const VerificationEmailSent = lazy(() => import("common/account/verification_email_sent"));
const DocumentDetails = lazy(() => import("signer_portal/documents/details"));
const TransactionAuthGate = lazy(() => import("common/auth_gate/transaction"));
const MeetingCompleted = lazy(() => import("signer_portal/meeting/completed"));
const Uploader = lazy(() => import("signer_portal/documents/uploader"));
const UnsignedBundleRedirect = lazy(() => import("signer_portal/flow/unsigned_bundle_redirect"));
const BitLanding = lazy(() => import("common/signer/bit_landing"));
const RemoteWitnessMeeting = lazy(() => import("common/meeting/witness"));
const RemoteWitnessGate = lazy(() => import("signer_portal/remote_witness_meeting/gate"));
const RemoteWitnessWrapper = lazy(() => import("signer_portal/remote_witness_meeting/wrapper"));
const Meeting = lazy(() => import("signer_portal/meeting"));
const PreMeetingHacks = lazy(() => import("signer_portal/hacks/pre_meeting"));
const ViewDocumentBundle = lazy(
  () => import("signer_portal/documents/view_document_bundle/index_signer_bundle_view"),
);
const PaymentRequired = lazy(() => import("signer_portal/documents/payment_required"));
const PreMeetingAppFrameContainer = lazy(
  () => import("signer_portal/app_frame/pre_meeting/container"),
);
const CredibleWitnessLandingPage = lazy(() => import("signer_portal/credible_witness"));
const CredibleWitnessWrapper = lazy(() => import("signer_portal/credible_witness/wrapper"));
const DocumentPrepare = lazy(() => import("signer_portal/documents/prepare"));
const RealEstateSigning = lazy(() => import("signer_portal/mortgage/pre_meeting/container"));
const CustomerDetails = lazy(
  () => import("signer_portal/pre_meeting/customer_details/customer_details_container"),
);
const KbaQuiz = lazy(() => import("signer_portal/pre_meeting/kba_quiz/container"));
const IdentificationContainer = lazy(() => import("signer_portal/identification_check"));
const ResponsiveCredentialAnalysis = lazy(
  () => import("signer_portal/pre_meeting/responsive_credential_analysis"),
);
const IdentityVerification = lazy(() => import("common/identity_verification"));
const Lobby = lazy(() => import("signer_portal/lobby"));
const MeetingRequest = lazy(() => import("signer_portal/meeting_request/join_meeting_container"));
const TrustedRefereeMeeting = lazy(() => import("signer_portal/trusted_referee"));
const TrustedRefereeMeetingSuccess = lazy(
  () => import("signer_portal/trusted_referee/completed/success"),
);
const TrustedRefereeMeetingFailure = lazy(
  () => import("signer_portal/trusted_referee/completed/failure"),
);
const EsignCompleted = lazy(() => import("signer_portal/esign/completed"));

const NotaryJournal = lazy(() => import("common/notary/journal"));
const NotaryJournalCertifyCopy = lazy(() => import("common/notary/journal/certify_copy"));
const NotaryJournalDownload = lazy(() => import("common/notary/journal/download"));
const NotaryJournalCopyRequest = lazy(() => import("common/notary/journal/copy_request"));
const NotaryOnboarding = lazy(() => import("common/notary/onboarding"));
const ExemplarDownload = lazy(() => import("common/notary/exemplar/download"));

const CompanyDirectory = lazy(() =>
  import("signer_portal/command_center/company_directory").then((module) => ({
    default: module.CompanyDirectory,
  })),
);
const SecuritySideNav = lazy(() =>
  import("common/organization/access/side_nav").then((module) => ({
    default: module.SecuritySideNav,
  })),
);
const DomainVerification = lazy(() =>
  import("common/organization/access/domains").then((module) => ({
    default: module.DomainVerification,
  })),
);
const DomainPolicies = lazy(() =>
  import("common/organization/access/domains/policies").then((module) => ({
    default: module.DomainPolicies,
  })),
);
const ClaimDomain = lazy(() =>
  import("common/organization/access/domains/claim").then((module) => ({
    default: module.ClaimDomain,
  })),
);
const IdentityProviders = lazy(() =>
  import("common/organization/access/identity_providers").then((module) => ({
    default: module.IdentityProviders,
  })),
);
const IdentityProviderConfiguration = lazy(() =>
  import("common/organization/access/identity_providers/view_idp").then((module) => ({
    default: module.IdentityProviderConfiguration,
  })),
);
const ConfigureNewIdp = lazy(() =>
  import("common/organization/access/identity_providers/create_idp").then((module) => ({
    default: module.ConfigureNewIdp,
  })),
);

const ENTRY = APP.CUSTOMER;
const { hasHacksEnabled } = Env;

type SignerAppFrameProps = ComponentProps<typeof DefaultAppFrameWithQuery> & {
  children?: ReactElement;
};

function SignerAppFrame(props: SignerAppFrameProps) {
  return (
    <ProofFrame portal="app">
      <ClearPendoTransforms />
      {props.children || <Outlet />}
    </ProofFrame>
  );
}

const ViewerManager = createViewerManager<Viewer>(ViewerQuery, [
  useSignerFeatureGatingSpecialization,
]);
const LightweightWrapper = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TermsOfServiceBlockingAutoAcceptForNewUsers,
  TierBlocker,
  SupportChat,
  Logger,
  MfaSetupBlocker,
);
const DefaultWrapper = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TermsOfServiceBlockingAutoAcceptForNewUsers,
  TierBlocker,
  CustomerRedirectChecker,
  Logger,
  MfaSetupBlocker,
  LimitedSessionWrapper,
);
const DefaultWrapperWithAppFrame = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TermsOfServiceBlockingAutoAcceptForNewUsers,
  TierBlocker,
  CustomerRedirectChecker,
  Logger,
  MfaSetupBlocker,
  LimitedSessionWrapper,
);
const DefaultWrapperWithAppFrameWithoutMfa = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TermsOfServiceBlockingAutoAcceptForNewUsers,
  TierBlocker,
  CustomerRedirectChecker,
  Logger,
  LimitedSessionWrapper,
);

const DefaultWrapperUnauth = composeViewerWrappers<Viewer>(SupportChat);
const RecipientColorWrapper = composeViewerWrappers<Viewer>(RecipientColorProvider);

const PreMeetingWrapper = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TermsOfServiceBlocking,
  TierBlocker,
  CustomerRedirectChecker,
  Logger,
  MfaSetupBlocker,
  PreMeetingRedirect,
);

const WrapperWithUpdatedTos = composeViewerWrappers<Viewer>(
  OrganizationRoleContext,
  InitialActiveOrganizationSetter,
  TierBlocker,
  UpdatedTosBlockingWrapper,
  CustomerRedirectChecker,
  Logger,
  MfaSetupBlocker,
);

function RouteWrapperInner({
  children,
  withPartnerLogo,
}: {
  children?: ReactElement;
  withPartnerLogo?: boolean;
}) {
  return (
    <SignerAppFrame withPartnerLogo={withPartnerLogo}>
      <Suspense fallback={<LoadingIndicator />}>{children || <Outlet />}</Suspense>
    </SignerAppFrame>
  );
}

function UpdatedTosBlockingWrapper({
  viewer,
  children,
}: {
  children?: ReactElement;
  viewer: Viewer;
}) {
  const neverAcceptedTou = viewer.user?.applicableTou.every((tou) => !tou.acceptedAt);
  return (
    <>
      {neverAcceptedTou ? (
        children
      ) : (
        <TermsOfServiceBlockingAutoAcceptForNewUsers viewer={viewer}>
          {children || <></>}
        </TermsOfServiceBlockingAutoAcceptForNewUsers>
      )}
    </>
  );
}

/* Use as Route element where possible so that parent element stays the same between routes, ensuring single page navigation and preventing unneccessary refresh. Additionally, the <Suspense/> in <RouteWrapperInner/> will keep the nav/sidebar mounted and only put up a loading indicator for the main content when lazy loading. */
function RouteWrapper({
  children,
  withoutMfa,
  withPartnerLogo,
}: {
  children?: ReactElement;
  withoutMfa?: boolean;
  withPartnerLogo?: boolean;
}) {
  return withoutMfa ? (
    <DefaultWrapperWithAppFrameWithoutMfa>
      <RouteWrapperInner>{children}</RouteWrapperInner>
    </DefaultWrapperWithAppFrameWithoutMfa>
  ) : (
    <DefaultWrapperWithAppFrame>
      <RouteWrapperInner withPartnerLogo={withPartnerLogo}>{children}</RouteWrapperInner>
    </DefaultWrapperWithAppFrame>
  );
}

function SettingsV2Wrapper({ children }: { children: ReactElement }) {
  const [searchParams] = useSearchParams();
  const isAppEmbed = searchParams.get("appEmbed") === "true";
  return isAppEmbed ? <>{children}</> : <RouteWrapper withoutMfa>{children}</RouteWrapper>;
}

function RedirectToBusinessPro() {
  useEffect(() => {
    redirectToSubdomain(AppSubdomains.business, `/signup/${TIER_PATH.BUSINESS_PRO_ODN}`);
  }, []);
  return null;
}

const UNAUTH_ROUTES = (
  <Route element={<DefaultWrapperUnauth />}>
    <Route path="/login" element={<Login entry={ENTRY} />} />

    <Route path="/reset_password/:token" element={<ResetPassword />} />

    <Route path="/bundle">
      <Route path=":globalID/credible-witness-landing/*" element={<CredibleWitnessWrapper />} />
    </Route>

    <Route path="/remote-witness-landing/:meetingId" element={<RemoteWitnessWrapper />} />
    <Route path="/signup">
      {/* Some of these are also in AUTH ROUTES */}
      {/* All signup paths now redirect to /signup/proodn on the business portal */}
      <Route path={TIER_PATH.BASIC} element={<RedirectToBusinessPro />} />
      <Route path={TIER_PATH.PRO} element={<RedirectToBusinessPro />} />
      <Route path={TIER_PATH.PRO_TRIAL} element={<RedirectToBusinessPro />} />
      <Route path={TIER_PATH.ELITE} element={<RedirectToBusinessPro />} />
      <Route index element={<Navigate replace to={TIER_PATH.BASIC} />} />
      <Route path="*" element={<Navigate replace to={TIER_PATH.BASIC} />} />
    </Route>

    <Route path="*" element={<HardLoginRedirect />} />
  </Route>
);

const authRoutes = (commandCenterAccessControlsFlag: boolean) => {
  return (
    <>
      {/* Command Center Routes */}
      {/* Is it's own "portal" with a unique logo in the global nav, but lives under "app" */}
      <Route
        path={COMMAND_CENTER_PATH}
        element={
          <PermissionRedirect
            redirectRoute={<RedirectRoot />}
            permissions={["accessCommandCenter"]}
          >
            <DefaultWrapper />
          </PermissionRedirect>
        }
      >
        <Route index element={<Navigate to="company-directory" />} />
        <Route
          path="company-directory"
          element={
            <RouteWrapper>
              <CompanyDirectory />
            </RouteWrapper>
          }
        />

        {commandCenterAccessControlsFlag && (
          <Route
            path="access"
            element={
              <RouteWrapper>
                <PermissionRedirect
                  redirectRoute={<RedirectRoot />}
                  permissions={["commandCenterAccessControls"]}
                />
              </RouteWrapper>
            }
          >
            <Route index element={<Navigate to="domains" />} />

            <Route path="domains">
              <Route
                index
                element={
                  <SecuritySideNav>
                    <DomainVerification />
                  </SecuritySideNav>
                }
              />
              <Route path="claim-domain" element={<ClaimDomain />} />
              <Route path=":domain/policies" element={<DomainPolicies />} />
            </Route>

            <Route path="identity-providers">
              <Route
                index
                element={
                  <SecuritySideNav>
                    <IdentityProviders />
                  </SecuritySideNav>
                }
              />
              <Route path="new" element={<ConfigureNewIdp />} />
              <Route path=":idpId/configuration" element={<IdentityProviderConfiguration />} />
            </Route>
          </Route>
        )}

        {makeUserManagementRoutes({ wrapper: <RouteWrapper />, isCommandCenter: true })}
      </Route>

      {/* needed to prevent redirecting to default route in authRoutes after signup */}
      <Route path="/signup/*" element={<LoadingIndicator />} />
      <Route path="/remote-witness-landing/:meetingId" element={<RemoteWitnessWrapper />} />

      <Route path="/portal-gateway" element={<PortalGateway />} />
      <Route path="/login" element={<PortalGateway />} />

      <Route
        path="/mfa_sms_setup"
        element={
          <RouteWrapper withoutMfa>
            <SmsMfaSetup />
          </RouteWrapper>
        }
      />

      <Route
        path="/mfa_totp_setup"
        element={
          <RouteWrapper withoutMfa>
            <TotpMfaSetup />
          </RouteWrapper>
        }
      />

      <Route
        path="/settings/*"
        element={
          <SettingsV2Wrapper>
            <Settings signerSettingsOnly />
          </SettingsV2Wrapper>
        }
      />

      <Route
        path="/tech-check/:stepId"
        element={
          <RouteWrapper>
            <NotaryTechCheck />
          </RouteWrapper>
        }
      />

      <Route path={SIGN_PATH}>
        <Route
          index
          element={
            <RouteWrapper>
              <Navigate to="/bundle/records?filter=all&page=1" />
            </RouteWrapper>
          }
        />

        <Route
          path="records"
          element={
            <RouteWrapper>
              <SignerDashboard />
            </RouteWrapper>
          }
        >
          <Route
            path=":globalID/*"
            element={
              <TransactionAuthGate source="bundle">
                <DocumentDetails />
              </TransactionAuthGate>
            }
          />
        </Route>
        {/* end /bundle/records */}

        <Route
          path=":bundleId/sign"
          element={
            <TransactionAuthGate source="bundle">
              <RealEstateSigning />
            </TransactionAuthGate>
          }
        />

        <Route
          path=":documentBundleId/lobby"
          element={
            <TransactionAuthGate source="bundle">
              <BundleAnalyticsContextRootContainer>
                <DefaultWrapper>
                  <PreMeetingAppFrameContainer>
                    <Lobby />
                  </PreMeetingAppFrameContainer>
                </DefaultWrapper>
              </BundleAnalyticsContextRootContainer>
            </TransactionAuthGate>
          }
        />

        <Route
          path=":globalID"
          element={
            <TransactionAuthGate source="bundle">
              <BundleAnalyticsContextRootContainer />
            </TransactionAuthGate>
          }
        >
          <Route
            path="requires_payment"
            element={
              <RouteWrapper withPartnerLogo>
                <PaymentRequired />
              </RouteWrapper>
            }
          />

          <Route element={<PreMeetingWrapper />}>
            <Route path="view" element={<ViewDocumentBundle />} />
            <Route path="prepare" element={<DocumentPrepare />} />
            <Route element={<PreMeetingAppFrameContainer />}>
              <Route path="customer-details" element={<CustomerDetails />} />
              <Route path="kba-quiz" element={<KbaQuiz />} />
              <Route path="credible-witness-landing" element={<CredibleWitnessLandingPage />} />
              <Route path="id_check/:stepId" element={<IdentificationContainer />} />
              <Route path="credential-analysis" element={<ResponsiveCredentialAnalysis />} />
              <Route path="identity-verification" element={<IdentityVerification />} />
              <Route path="proof-id" element={<UserCertificate />} />
              <Route path="meeting_request" element={<MeetingRequest />} />
            </Route>
            {/* end /bundle/:globalID PreMeetingAppFrameContainer grouping */}
          </Route>
          {/* end /bundle/:globalID DefaultWrapper grouping */}
        </Route>
        {/* end /bundle/:globalID (signer preflow) */}
      </Route>

      <Route
        path="/upload"
        element={
          <RouteWrapper>
            <Uploader />
          </RouteWrapper>
        }
      />

      <Route
        path="/landing"
        element={
          <WrapperWithUpdatedTos>
            <BitLanding />
          </WrapperWithUpdatedTos>
        }
      />

      {hasHacksEnabled && (
        <Route
          path={PRE_MEETING_HACKS_PATH}
          element={
            <RouteWrapper>
              <PreMeetingHacks />
            </RouteWrapper>
          }
        />
      )}

      <Route path="/meeting" element={<TransactionAuthGate source="meeting" />}>
        <Route
          path=":globalID/completed"
          element={
            <DefaultWrapper>
              <MeetingCompleted />
            </DefaultWrapper>
          }
        />
        <Route
          path=":meetingId"
          element={
            <DefaultWrapper>
              <Meeting />
            </DefaultWrapper>
          }
        />
      </Route>

      <Route path="referee-meeting">
        <Route
          path=":meetingId"
          element={
            <DefaultWrapper>
              <TrustedRefereeMeeting />
            </DefaultWrapper>
          }
        />
        <Route path=":meetingId/completed/success" element={<TrustedRefereeMeetingSuccess />} />
        <Route path=":meetingId/completed/failure" element={<TrustedRefereeMeetingFailure />} />
      </Route>

      <Route path={JOURNAL_BASE_PATH} element={<RouteWrapper withoutMfa />}>
        <Route path={COPY_REQUEST_PATH} element={<NotaryJournalCopyRequest />} />
        <Route path={CERTIFY_PATH} element={<NotaryJournalCertifyCopy />} />
        <Route path={DOWNLOAD_EXPORT_PATH} element={<NotaryJournalDownload />} />
        <Route index element={<NotaryJournal />} />
      </Route>

      <Route
        path={"/exemplar/download-export/:documentBundleId"}
        element={
          <RouteWrapper>
            <ExemplarDownload />
          </RouteWrapper>
        }
      />

      <Route
        path="esign/:globalID/completed"
        element={
          <DefaultWrapper>
            <EsignCompleted />
          </DefaultWrapper>
        }
      />

      <Route path="/settings/notary" element={<RouteWrapper />}>
        <Route path="onboarding" element={<NotaryOnboarding />} />
        <Route path="onboarding/:stepId" element={<NotaryOnboarding />} />
      </Route>

      <Route
        path="*"
        element={
          <LightweightWrapper>
            <UnsignedBundleRedirect>
              <RedirectRoot />
            </UnsignedBundleRedirect>
          </LightweightWrapper>
        }
      />
    </>
  );
};

function SignerRouter() {
  const isAuthenticated = useIsAuthenticated();
  const commandCenterAccessControlsFlag = useCommandCenterAccessControls();
  const NOTARY_ONBOARDING_PATH = "/settings/notary/onboarding";

  if (useMatch("/shortcut-cheatsheet")) {
    return <ShortcutCheatsheet />;
  }
  return (
    <TargetAuthLinkHandler>
      <App isAuthenticated={isAuthenticated} mobileSupported>
        <Routes>
          <Route element={<RecipientColorWrapper />}>
            <Route
              // intended only for notaries
              path="/activate/:token"
              element={<Activation redirectTo={NOTARY_ONBOARDING_PATH} />}
            />
            <Route
              path="/activate-transaction"
              element={
                <DefaultWrapperUnauth>
                  <HandleTransactionLink />
                </DefaultWrapperUnauth>
              }
            />

            <Route path="/claim" element={<SigningGroupClaim />} />

            <Route path="/verify-account" element={<VerifyEmail />} />

            <Route path="/session_expired" element={<SessionExpiredModal />} />

            {/* Both routes supported for backward compatibility */}
            <Route path="/guest/transaction" element={<EasyLink />} />
            <Route path="/easy-link" element={<EasyLink />} />
            <Route path="/signup/upload" element={<GuestSignup />} />

            <Route path="/referral" element={<ReferralHandler />} />

            <Route
              path="/dropbox"
              element={
                <DefaultWrapperUnauth>
                  <Dropbox />
                </DefaultWrapperUnauth>
              }
            />

            <Route
              path="/upgrade"
              element={
                <DefaultWrapperUnauth>
                  <TierUpgrade />
                </DefaultWrapperUnauth>
              }
            />

            <Route path="/verification-email-sent" element={<VerificationEmailSent />} />

            <Route path="/meetings/:meeting_id" element={<MeetingRedirect />} />

            <Route
              path="/remote-witness-meeting/:meetingId"
              element={
                <RemoteWitnessGate>
                  <RemoteWitnessMeeting />
                </RemoteWitnessGate>
              }
            />

            {isAuthenticated ? authRoutes(commandCenterAccessControlsFlag) : UNAUTH_ROUTES}
          </Route>
        </Routes>
      </App>
    </TargetAuthLinkHandler>
  );
}

const ROOT = (
  <ViewerManager>
    <SignerRouter />
  </ViewerManager>
);

export default () => ROOT;
